
















import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { RichTextItemInterface } from '@amplience/types';

export default defineComponent({
  name: 'RichText',
  props: {
    title: {
      type: String,
      default: '',
    },
    richText: {
      type: Array as PropType<RichTextItemInterface[]>,
      default: () => [],
    },
  },
});
